<template>
	<div>
		<WrapperSubHeader>
			<span>{{ t("guide.guide", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
		</WrapperSubHeader>
		<div class="w-10/12 md:w-3/6 mx-auto mt-10 space-y-10">
			<div class="md:w-full h-62 mx-auto rounded-lg bg-white shadow">
				<div class="w-full flex flex-col lg:flex-row justify-evenly items-center">
					<div class="flex flex-col justify-center lg:justify-center items-center mb-3 py-5 lg:mb-0">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
						</svg>
						<h5 class="card-dashed-title text-xs 2xl:text-lg">{{ t("guide.question", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
						<p class="text-gray-500 font-light text-xs xl:text-sm">
							{{ t("guide.visit", {}, { locale: $store.state.Langs.selected.lang }) }}
							<a target="_blank" :href="`https://help.setup.baymark.net/es/docs`" class="text-indigo-400 hover:text-indigo-500 font-semibold underline">
								{{ t("guide.help", {}, { locale: $store.state.Langs.selected.lang }) }}
							</a>
						</p>
					</div>
				</div>
			</div>

			<div class="md:w-full mx-auto card-dashed flex gap-2">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div class="w-full flex justify-between items-center">
						<div class="hidden md:flex flex-shrink-0">
							<InformationCircleIcon class="h-5 w-5 text-indigo-400" aria-hidden="true" />
						</div>
						<div class="ml-3 flex-1 flex flex-col justify-center items-center md:flex-row md:items-center md:justify-between">
							<p class="mb-3 md:mb-0 text-xs lg:text-sm text-black">
								{{ t("guide.firtsChannel", {}, { locale: $store.state.Langs.selected.lang }) }}
							</p>
							<router-link :to="{ name: 'Integrations', params: { id: $store.state.Projects.idRoute } }" class="btn no-animation btn-info hover:bg-indigo-500 btn-xs">
								<span>{{ t("guide.integrations", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useI18n } from "vue-i18n"
import Logo from "@/assets/world.svg"
import WrapperSubHeader from "@/components/WrapperSubHeader"
import { InformationCircleIcon } from "@heroicons/vue/solid"

export default {
	name: `Guide`,
	components: {
		WrapperSubHeader,
		InformationCircleIcon
	},
	setup() {
		const { t, locale } = useI18n()
		return { t, locale }
	},
	data: function () {
		return {
			Image: Logo,
			Title: "Guide",
			hasProject: true
		}
	}
}
</script>

